
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { EnumColumnConfig } from "@/components/controls/catalogueGrid/columns/enumColumnConfig";
import { DateColumnConfig } from "@/components/controls/catalogueGrid/columns/dateColumnConfig";
import planInfoDialog from "@/components/info/PlanInfoDialog.vue";
import ManagerPlanDebugInfoDialog from "@/components/journals/PlanJournal/ManagerPlanDebugInfoDialog.vue";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //установленные фильтры
    filters: { required: false },

    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
  },
  computed: {
    planDebugInfoDialog(): any {
      return ManagerPlanDebugInfoDialog;
    },
  },
  methods: {
    onContentReady(e: any) {
      if (!this.customFilterApplied && this.filters) {
        const grid = e.component;
        this.filters.forEach((x: { column: any; value: any }) => {
          grid.columnOption(x.column, "filterValue", x.value);
        });
        this.customFilterApplied = true;
      }
    },
    openDebugDialog(e: any): void {
      return this.popupDebugInfo().open(e.row.data.debugInfo);
    },
    popupDebugInfo() {
      return this.$refs.popupDebugInfo as any;
    },
    onToolbarPreparing: function (e: any) {
      let toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach(function (item) {
        item.location = "after";
      });
    },
  },
  data() {
    return {
      customFilterApplied: false,
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: true,
        allowDeleteMultipleRows: false,
        allowCopy: false,
        pageEditing: true,
        pageName: "plan",
        allowChangeScrollSettings: true,
        allowColumnReordering: true,
        allowResetSelectedRows: true,
        chooseColumns: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      columns: [
        new IntegerColumnConfig({
          dataField: "id",
          caption: "Код",
          readOnly: true,
          editVisible: false,
          format: ""
        }),
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
          validationRules: [requiredRule],
        }),
        new EnumColumnConfig({
          dataField: "stateDescription",
          caption: "Тип",
          enumType: "planState",
          modelField: "state",
          modelDescField: "stateDescription",
          descriptionColumnName: "stateDescription",
          validationRules: [requiredRule],
        }),
        new DateColumnConfig({
          dataField: "calcRunningDate",
          caption: "Дата запуска решения",
          mode: "datetime",
        }),
        new DateColumnConfig({
          dataField: "calcCompletedDate",
          caption: "Дата расчета",
          mode: "datetime",
        }),
        new DateColumnConfig({
          dataField: "creationDate",
          caption: "Дата создания",
          mode: "datetime",
          validationRules: [requiredRule],
          sortOrder: "desc",
        }),
        new TextColumnConfig({
          dataField: "calcUserDescription",
          caption: "Пользователь",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "companyDescription",
          caption: "Компания",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "planCargoInfo",
          caption: "Грузы",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "planTransportInfo",
          caption: "Транспорт",
          validationRules: [requiredRule],
        }),
      ],
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        heightScroll: !this.selectMode ? "calc(100vh - 205px)" : "400px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "single",
      }),
      pageSizes: [10, 20, 50, 100],
      editButtons: [],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }
  },
  mounted() {
    (this.$refs.grid as any).editButtons = [
      {
        hint: "Просмотр",
        icon: "edit",
        onClick: (this.$refs.grid as any).editRow,
      },
      {
        hint: "Дебаг инфо",
        icon: "info",
        onClick: this.openDebugDialog,
        visible(e) {
          return e.row.data.debugInfo;
        },
      },
    ];
  },
});
